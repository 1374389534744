import client from '../utils/client';
import {ACCOMMODATIONS_SEARCH} from '../queries/accommodations';

const accommodationSearch = (name, callback) => {
  client.query({ query: ACCOMMODATIONS_SEARCH, variables: { name }})
    .then(({ data }) => {
      if(data && data.accommodationSearch){
        callback(data.accommodationSearch)
      } else {
        console.error(data)
      }
    })
    .catch(error => console.error(error))
}

export default {
  accommodationSearch
}
