import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  fetchUrl: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}

class TypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showTray: false,
      selectedAccommodationName: '',
    }
  }

  async componentDidMount() {
    const { fetchUrl } = this.props;
    const data = await this.fetch(fetchUrl)
    this.setState({ data });
  }

  fetch = async (url) => {
    const response = await fetch(url)
    return await response.json();
  }

  onChangeInput = async (e) => {
    const { value } = e.target;
    const { fetchUrl } = this.props;

    const data = await this.fetch(`${fetchUrl}?name=${value}`);
    this.setState({ selectedAccommodationName: value, data })
  }

  onClickAccommodationSpan = (event, id) => {
    const { innerText } = event.target;
    const { props: { setSelected } } = this;

    this.setState({ selectedAccommodationName: innerText, showTray: false }, setSelected(id));
  }

  render() {
    const { state: { data, showTray, selectedAccommodationName }, props: { label } } = this;
    const content = data.map((accommodation) => {
      return (
        <span key={`accommodation-${accommodation.id}`}
          onClick={(e) => this.onClickAccommodationSpan(e, accommodation) }>
          {accommodation.name}
        </span>
      )
    })

    return(
      <Fragment>
        <label htmlFor='name-control'>{label}</label>
        <input id='name-control'
               onFocus={() => { this.setState({ showTray: true }) }}
               onChange={this.onChangeInput} value={selectedAccommodationName}
               type={'search'}
        />
        {showTray &&
          <div id='list-wrapper' className='dropdown'>
            {content}
          </div>
        }
      </Fragment>
    )
  }
}

TypeSelect.propTypes = propTypes;
export default TypeSelect;
