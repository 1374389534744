import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react';
import Modal from 'react-modal';

import CreateDeductionForm from './CreateDeductionForm';
import {modalMedium} from '../utils/styles/modalStyles';

Modal.setAppElement('#reactModalAnchor');

const CreateDeduction = observer(({ store }) => {
  const [formVisible, setFormVisible] = useState(false);
  return(
    <Fragment>
      <button onClick={() => { setFormVisible(true) }} className='button'>Create Deduction</button>
      <Modal isOpen={formVisible} style={modalMedium}>
        <CreateDeductionForm store={store} setFormVisible={setFormVisible} />
      </Modal>
    </Fragment>
  )
})

export default CreateDeduction;
