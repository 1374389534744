import {gql} from '@apollo/client'

export const SAVE_ACCOMMODATION_OPTION_MUTATION = gql`
    mutation saveOption(
        $id: ID,
        $accommodationId: ID!,
        $maxOccupancy: Int,
        $roomName: String,
        $roomType: String,
        $rates: [ratesInput!]
    ){
        saveOption: saveAccommodationOption(
            id: $id,
            accommodationId: $accommodationId,
            maxOccupancy: $maxOccupancy,
            roomName: $roomName,
            roomType: $roomType,
            rates: $rates
        ){
            accommodationOption {
                id
                roomName
                roomType
                maxOccupancy
                accommodationId
                rates {
                    id
                    baseRate
                    rackRate
                    currency
                    startDate
                    endDate
                    rateType
                }
            }
            errors {
                field
                fullMessage
                message
            }
        }
    }
`;
