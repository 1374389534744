import {SEARCH_ROOM_PRICES_QUERY} from '../queries/bookings';

const calculatePrice = ({client, accommodationId, checkinDate, checkoutDate, numberOfChildren, numberOfAdults, numberOfRooms, setPriceList }) => {
  const priceSearchInput = {
    accommodationId,
    checkinDate,
    checkoutDate,
    numberOfChildren,
    numberOfAdults,
    numberOfRooms
  }

  client.query({ query: SEARCH_ROOM_PRICES_QUERY, variables: { input: priceSearchInput } })
    .then(({data}) => {
      if(data && data.options) {
        setPriceList(data.options);
      } else {
        console.log(data)
        setPriceList([]);
      }
    })
    .catch(error => console.error(error))
}

export default calculatePrice;
