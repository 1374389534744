import React from 'react';

import { accommodationOptionsUrl } from '../utils/routes/adminAccommodationRoutes';

const AccommodationsListing = ({ accommodations }) => {
  const rows = accommodations.map((acc) => {
    return(
      <tr key={`accommodation-${acc.id}`}>
        <td>{acc.id}</td>
        <td><a className='underlined blue-link' href={accommodationOptionsUrl(acc.slug)}>{acc.name}</a></td>
        <td><a className='underlined blue-link' href={`/admin/locations/${acc.location.slug}`}>{acc.location.name}</a></td>
        <td>
          <a className='underlined blue-link' href={`/admin/accommodations/${acc.slug}`}>View</a>{' '}|{' '}
          <a className='underlined blue-link' href={`/admin/accommodations/${acc.slug}/edit`}>Edit</a>{' '}|{' '}
          { /* <a className='underlined blue-link' href={`/admin/locations/${acc.location.slug}`}>Delete</a>{' '}|{' '} */ }
          <a className='underlined blue-link' href={accommodationOptionsUrl(acc.slug)}>Options</a>
        </td>
      </tr>
    )
  })
  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Location</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
}

export default AccommodationsListing;
