import React, {useState} from 'react';
import {observer} from 'mobx-react';

const AccommodationOptionRatesListing = observer(({ rates }) => {
  const [editingRateId, setEditingRateId] = useState(null);

  const showInputOrVal = (rateId, val) => {
    if(editingRateId === rateId) return <input name={`rate-${rateId}`} onChange={() => {}} value={val}/>
    return val
  }

  const rows = rates.map((rate) => {
    const id = rate.id
    return(
      <tr key={`rate-row-${id}`}>
        <td>{id}</td>
        <td>{showInputOrVal(id, rate.baseRate)}</td>
        <td>{showInputOrVal(id, rate.startDate)}</td>
        <td>{showInputOrVal(id, rate.endDate)}</td>
        <td>{`${rate.rackRate ? 'YES' : 'NO'}`}</td>
        <td>{rate.rateType}</td>
      </tr>
    )
  });

  return(
    <section className={'row'}>
      <h3>Rates</h3>
      <table className='basic-table'>
        <thead>
        <tr>
          <th>ID</th>
          <th>Base Rate</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Rack Rate?</th>
          <th>Rate Type</th>
        </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </table>
    </section>
  )
});

export default AccommodationOptionRatesListing;
