import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';

import calculatePrice from './utils/calculatePrice.js';
import client from './utils/client';

const propTypes = {
  id: PropTypes.number.isRequired,
};

class PricingCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinDate: undefined,
      checkoutDate: undefined,
      accommodationOptionId: undefined,
      numberOfRooms: 1,
      numberOfAdults: 1,
      numberOfChildren: 0,
      loading: false,
      priceList: [],
    };

    this.handleCheckinDateChange = this.handleCheckinDateChange.bind(this);
    this.handleCheckoutDateChange = this.handleCheckoutDateChange.bind(this);
    this.handleAccommodationOptionChange = this.handleAccommodationOptionChange.bind(this);
  }

  handleCheckinDateChange(checkinDate) {
    this.setState({ checkinDate }, this.calculatePricing);
  }

  handleCheckoutDateChange(checkoutDate) {
    this.setState({ checkoutDate }, this.calculatePricing);
  }

  handleAccommodationOptionChange(e) {
    this.setState({ accommodationOptionId: e.target.value }, this.calculatePricing)
  }

  setPriceList = (data) => {
    this.setState({ priceList: data })
  }

  calculatePricing(){
    const { state: { checkinDate, checkoutDate, numberOfAdults, numberOfChildren, numberOfRooms }, props: { id } } = this;
    if(!checkinDate || !checkoutDate) { return }

    this.setState({ loading: true });
    calculatePrice({
      client,
      accommodationId: id,
      checkinDate,
      checkoutDate,
      numberOfChildren,
      numberOfAdults,
      numberOfRooms,
      setPriceList: this.setPriceList,
    });
  }

  setCounterAttribute = ({name, value}) => {
    this.setState({[name]: parseInt(value)}, this.calculatePricing)
  }

  render () {
    const {
      state: { checkinDate, checkoutDate, numberOfAdults, numberOfChildren, numberOfRooms, priceList }
    } = this;

    const pricedOptions = priceList.map((item) => {
      const { rate: { totalRateDetails, perNightRate, singlePerNight, doublePerNight, total }, accommodationOption: { id, roomName, maxOccupancy } } = item;
      return(
        <div className='col-md-12' key={`price-${id}`}>
          <a href='#' className='img-box alternative-imagebox' data-background-image='images/popular-location-03.jpg'>
            <div className='visible img-box-content'>
              <h4>{roomName}</h4>
              <p className='underlined'>Sleeps: {maxOccupancy}</p>

              <p><i className='fa fa-user'></i>  {singlePerNight}, <i className='fa fa-user'></i><i className='fa fa-user'></i> {doublePerNight}</p>
              <p>Your rate per night: {perNightRate}</p>
              <p>Your total: {total}</p>
              <p className='italic'>({totalRateDetails})</p>
            </div>
            <div className='img-box-background' style={{ backgroundImage: `url(/images/popular-location-03.jpg)`}}>{' '}</div>
          </a>
        </div>
      )
    })

    return (
      <Fragment>
        <h3><i className="fa fa-tags"/> Pricing</h3>
        <div className="row margin-top-0">
          <div className="row margin-top-0">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label>Check-in date</label>
              <DayPickerInput
                value={checkinDate}
                format='DD/MM/YYYY'
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder='Checkin date'
                onDayChange={this.handleCheckinDateChange}
                dayPickerProps={{
                  disabledDays: [{ before: moment().add(5, 'day').toDate()}]
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <label>Checkout date</label>
            <DayPickerInput
              value={checkoutDate}
              format='DD/MM/YYYY'
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder='Checkout date'
              onDayChange={this.handleCheckoutDateChange}
              dayPickerProps={{
                disabledDays: [{
                  before: checkinDate ? moment(checkinDate).add(1, 'day').toDate() : moment().add(1, 'day').toDate()}]
              }}
            />
          </div>
          </div>
          <div className="row margin-top-0">
            <div className="col-md-4">
              <label> Adult(s)</label>
              <input id='numberOfAdults'
                     type='number'
                     name='numberOfAdults'
                     value={numberOfAdults}
                     min='1'
                     max='99'
                     onChange= {
                       (e) => { this.setCounterAttribute({ name: 'numberOfAdults', value: e.target.value })}
                     }
              />
            </div>
            <div className="col-md-4">
              <label> Children </label>
              <input id='numberOfChildren'
                     type='number'
                     name='numberOfChildren'
                     value={numberOfChildren}
                     min='0'
                     max='99'
                     onChange={
                       (e) => { this.setCounterAttribute({ name: 'numberOfChildren', value: e.target.value })}
                     }/>
            </div>
            <div className="col-md-4">
              <label> Room(s) </label>
              <input id='numberOfRooms'
                     type='number'
                     name='numberOfRooms'
                     value={numberOfRooms}
                     min='1'
                     max='99'
                     onChange={
                       (e) => { this.setCounterAttribute({ name: 'numberOfRooms', value: e.target.value })}
                     }/>
            </div>
          </div>
          <div className="col-md-12 price-row">
            { pricedOptions }
          </div>
        </div>
      </Fragment>
    );
  }
}

PricingCalculator.propTypes = propTypes;
export default PricingCalculator
