import React, {Fragment, useState} from 'react';
import Modal from 'react-modal';
import {observer} from 'mobx-react';

import Input from '../Input';
import {modalMedium} from '../utils/styles/modalStyles';

Modal.setAppElement('#reactModalAnchor');

const EditBillingAccountForm = ({ store,  billingAccount}) => {
  const [formVisible, setFormVisible] = useState(false);

  const onLinkClicked = () => {
    store.setActiveBillingAccount(billingAccount);
    setFormVisible(true);
  }

  const updateAttribute = (attribute, value) => {
    store.setActiveBillingAccountAttribute(attribute, value);
  }

  const cancelEdit = () => {
    store.resetActiveBillingAccount();
    setFormVisible(false)
  }

  const save = () => {
    store.updateBillingAccount();
    setFormVisible(false);
  }

  return(
    <Fragment>
      <button className='link-button' onClick={onLinkClicked}>Edit</button>
      <Modal isOpen={formVisible} style={modalMedium}>
        <h2>Edit Billing Account</h2>
        <Input
          onChange={(e) => { updateAttribute('startDate', e.target.value)}}
          name='startDate'
          type='date'
          label='Start date'
          value={billingAccount.startDate}
        />
        <Input
          onChange={(e) => { updateAttribute('endDate', e.target.value)}}
          name='endDate'
          type='date'
          label='End date'
          value={billingAccount.endDate || '' }
        />
        <Input
          onChange={(e) => { updateAttribute('endDateReason', e.target.value)}}
          name='endDateReason'
          type='text'
          label='End date reason'
          value={billingAccount.endDateReason || '' }
        />
        <button className='button small' onClick={cancelEdit}>Cancel</button>
        <button className='button small' onClick={save}>Save</button>
      </Modal>
    </Fragment>
  )

}

export default observer(EditBillingAccountForm)
