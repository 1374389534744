import React, {Fragment} from 'react';

const LoggedInUser = ({user}) => {
  return(
    <Fragment>
      <div className="col-md-6">
        <label htmlFor="firstName">First Name</label>
        <input type='text' name='firstName' value={user.firstName} disabled={true} />
      </div>

      <div className="col-md-6">
        <label htmlFor="surname">Last Name</label>
        <input type='text' name='surname' value={user.lastName} disabled={true} />
      </div>

      <div className="col-md-6">
        <div className="input-with-icon medium-icons">
          <label htmlFor="email">E-Mail Address</label>
          <input type='email' name='email' value={user.email} disabled={true} />
          <i className="im im-icon-Mail"></i>
        </div>
      </div>

      <div className="col-md-6">
        <div className="input-with-icon medium-icons">
          <label htmlFor="cellphone">Phone</label>
          <input type='phone' name='cellphone' value={user.cellphone} disabled={true} />
          <i className="im im-icon-Phone-2"></i>
        </div>
      </div>
    </Fragment>
  )
}

export default LoggedInUser;
