import {gql} from '@apollo/client';

export const CREATE_QUICK_BOOKING_REQUEST_MUTATION = gql`
    mutation createQuickBookingRequest($firstName: String!, $lastName: String!, $email: String!,
        $cellphone: String!, $details: String!, $token: String) {
        createQuickBookingRequest(
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            cellphone: $cellphone,
            details: $details,
            token: $token
        ){
            bookingRequest {
                id
            }
            errors {
                field
                message
            }
        }
    }
`;
