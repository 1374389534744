import React from 'react';
import { WithWizard } from 'react-albus';

const Navigation = () => (
  <WithWizard
    render={({ next, previous, step, steps }) => (
      <div className="nav-buttons">
        {steps.indexOf(step) > 0 && (
          <button className="button preview back" onClick={previous}>
            Back
          </button>
        )}

        {steps.indexOf(step) < steps.length - 1 && (
          <button className="button preview next" onClick={next}>
            Next
          </button>
        )}
      </div>
    )}
  />
);

export default Navigation;
