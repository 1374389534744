import {makeAutoObservable, runInAction} from 'mobx';

import customerTransports from '../transports/customerTransports';

class CustomerStore {
  customer
  loading
  customerId
  actionDispatcher
  activeBillingAccount = {
    id: null,
    startDate: '',
    endDate: ''
  }
  activeDeduction = {
    d: null,
    amount: 0,
    description: '',
    deductionDate: ''
  }

  constructor(customerId) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.loading = true;
    this.customerId = customerId;
    this.actionDispatcher = customerTransports;

    this.fetchCustomer()
  }

  fetchCustomer(){
    console.log('fetching customer...')
    this.actionDispatcher.fetchCustomer({ customerId: this.customerId, callback: this.setCustomer, errorCallback: this.stopLoading })
  }

  refreshWallet(callback){
    this.actionDispatcher.refreshWallet(this.customerId, callback)
  }

  setCustomer(customer){
    runInAction(() => {
      this.customer = customer;
      this.stopLoading();
    })
  }

  stopLoading(){
    runInAction(() => {
      this.loading = false;
    })
  }

  // Deductions

  resetActiveDeduction(){
    runInAction(() => {
      this.activeDeduction = {
        id: null,
        amount: 0,
        description: '',
        deductionDate: ''
      }
    })
  }

  saveDeduction(){
    runInAction(() => {
      this.actionDispatcher.saveDeduction(this.activeDeduction, this.customer.getawayPointsWallet.id, this.fetchCustomer)
    })
  }

  deleteDeduction(id){
    runInAction(() => {
      this.actionDispatcher.deleteDeduction(id, this.fetchCustomer)
    })
  }

  setActiveDeductionAttribute(attribute, value){
    runInAction(() => {
      this.activeDeduction[attribute] = value
    })
  }

  // Billing Accounts

  resetActiveBillingAccount(){
    runInAction(() => {
      this.activeBillingAccount = {
        id: null,
        startDate: '',
        endDate: ''
      }
    })
  }

  setActiveBillingAccount(billingAccount){
    runInAction(() => {
      this.activeBillingAccount = billingAccount;
    })
  }

  setActiveBillingAccountAttribute(attribute, value){
    runInAction(() => {
      this.activeBillingAccount[attribute] = value
    })
  }

  updateBillingAccount(){
    runInAction(() => {
      this.actionDispatcher.updateBillingAccount(this.activeBillingAccount, this.fetchCustomer)
    })
  }
}

export default CustomerStore;
