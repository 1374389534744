import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react';
import Modal from 'react-modal';

import CreateAccommodationOptionForm from './CreateAccommodationOptionForm';

Modal.setAppElement('#reactModalAnchor');
const modalStyle = {
  content: {
    top: '20%',
    left: '30%',
    height: '250px',
    width: '550px',
  }
}

const CreateAccommodationOption = observer(({ store }) => {
  const [formVisible, setFormVisible] = useState(false);
  return(
    <Fragment>
      <button onClick={() => { setFormVisible(true) }} className='button'>Add Room</button>
      <Modal isOpen={formVisible} style={modalStyle}>
        <CreateAccommodationOptionForm store={store} setFormVisible={setFormVisible} />
      </Modal>
    </Fragment>
  )
})

export default CreateAccommodationOption;
