import React from 'react';
import { observer } from 'mobx-react';

import Input from '../Input';
import RegistrationStep from './RegistrationStep';

const Address = observer(({ id, address, setAttribute }) => {
  return(
    <RegistrationStep id={id}>
      <fieldset>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'address', e.target.value)}
                 id={'address'} name={'address'} type={'text'} label={'Address'} value={address.address} required={true} />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'addressTown', e.target.value)}
                 id={'addressTown'} name={'addressTown'} type={'text'} label={'City/Town'} value={address.addressTown} required={false} />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'addressSuburb', e.target.value)}
                 id={'addressSuburb'} name={'addressSuburb'} type={'text'} label={'Suburb'} value={address.addressSuburb} required={false} />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'postalCode', e.target.value)}
                 id={'postalCode'} name={'postalCode'} type={'text'} label={'Postal code'} value={address.postalCode} required={false} />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'province', e.target.value)}
                 id={'province'} name={'province'} type={'text'} label={'Province'} value={address.province} required={false} />
        </div>
      </fieldset>
    </RegistrationStep>
  )
});

export default Address;
