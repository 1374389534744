import React, {useState} from 'react';
import {observer} from 'mobx-react';

import BillingAccountsListing from './BillingAccountsListing';
import BusinessMemberships from './BusinessMembershipsListing';
import CreateDeduction from './CreateDeduction';
import CustomerStore from '../stores/CustomerStore';
import DebitOrdersListing from './DebitOrdersListing';
import DeductionsListing from './DeductionsListing';
import GetawayPointMovementsListing from './GetawayPointMovementsListing';
import ScoreCollectionsListing from './ScoreCollectionsListing';
import Spinner from '../Spinner';
import UnpaidDebitOrdersListing from './UnpaidDebitOrdersListing';
import ErrorBoundary from '../ErrorBoundary';
import PointsCalculationSummary from './PointsCalculationSummary';

const CustomerDetails = observer(({ store }) => {
  const { loading, customer, customerId } = store;
  const [activeTab, setActiveTab]  = useState('movements');

  const itemClassName = (id) => {
    if(activeTab === id) return 'active'

    return ''
  }

  const contentStyle = (id) => {
    if(activeTab === id) return 'shown'

    return 'hidden'
  }

  if(loading){
    return <Spinner size='large' />
  }

  const pointCalculationSummary = () => {
    if(!customer) return <p>...</p>;

    return customer.pointsCalculationSummary.map((summary) => {
      return(
        <PointsCalculationSummary summary={summary} key={`score-collection-id-${summary.collectionId}`} />
      )
    })
  }

  const totalDeductions = customer.getawayPointsDeductions.slice().reduce((previousValue, currentDeduction) => {
    return  previousValue + currentDeduction.amount
  }, 0);

  const totalPoints = customer.pointsCalculationSummary.reduce((previousValue, summary) => {
    return  previousValue + summary.pointsTotal
  }, 0);

  return(
    <div id={'wrapper'}>
      <div className={'container'}>
        <h1>Account history for: {customer.name}</h1>
        <div className='row customer-summary'>
          <div className='col-md-6'>
            <h3>Summary</h3>
            <p>Score Client ID: {customer.scoreClient.extScoreClientId}</p>
            <p>Current business status: TODO</p>
            <p>Current billing status: TODO</p>
            <p>Current wallet balance: {customer.getawayPointsWallet.balance}</p>
            <p>Current credits balance: {customer.getawayCreditsWallet.balance}</p>
          </div>
          <div className='col-md-6'>
            <h3>Points Calculation</h3>
            { pointCalculationSummary() }
            <div className='points-summary'>
              <p className='summary-row'>
                <span>Total Deductions:</span>
                <span></span>
                <span>R{totalDeductions}</span>
              </p>
              <p className='summary-row'>
                <span>Remaining Points:</span>
                <span>{totalPoints} - {totalDeductions}</span>
                <span>{(totalPoints - totalDeductions).toFixed(2)}</span>
              </p>
            </div>
          </div>
        </div>
        <button
          onClick={() => store.refreshWallet(() => { window.location.reload() })}
          className='button preview'>Refresh Wallet</button>

        <div className='row'>
          <div className='col-md-6'>
            <h3>Billing Accounts</h3>
            <BillingAccountsListing store={store} />
            <a href={`/admin/billing_accounts/${customerId}`}>Add Billing Account</a>
          </div>
          <div className='col-md-6'>
            <h3>Business Memberships</h3>
            <BusinessMemberships customerId={customerId} businessMemberships={customer.businessMemberships} />
            <a href={`/admin/billing_accounts/${customerId}`}>Add Business Account</a>
          </div>
        </div>

        <div className='row'>
          <ul className='tabs-nav'>
            <li className={itemClassName('movements')}><a className='cursor' onClick={() => setActiveTab('movements')}>Point Movements</a></li>
            <li className={itemClassName('collections')}><a className='cursor' onClick={() => setActiveTab('collections')}>Collections</a></li>
            <li className={itemClassName('deductions')}><a className='cursor' onClick={() => setActiveTab('deductions')}>Deductions</a></li>
            <li className={itemClassName('debit-orders')}><a className='cursor' onClick={() => setActiveTab('debit-orders')}>Debit Orders</a></li>
            <li className={itemClassName('unpaids')}><a className='cursor' onClick={() => setActiveTab('unpaids')}>Unpaids</a></li>
          </ul>

          <div className='tabs-container'>
            <div className={`tab-content ${contentStyle('movements')}`} id='movements'>
              <div className='col-md-12'>
                <h3>Getaway Point Movements</h3>
                <GetawayPointMovementsListing movements={customer.getawayPointsMovements} />
              </div>
            </div>

            <div className={`tab-content ${contentStyle('collections')}`} id='collections'>
              <div className='col-md-12'>
                <h3>Score Collections</h3>
                <ScoreCollectionsListing listings={customer.scoreClientCollections} />
              </div>
            </div>

            <div className={`tab-content ${contentStyle('deductions')}`} id='deductions'>
              <div className='col-md-12'>
                <h3>Getaway Points Deductions</h3>
                <CreateDeduction store={store} />
                <br /><br />
                <DeductionsListing getawayPointsDeductions={customer.getawayPointsDeductions} deleteDeduction={store.deleteDeduction} />
              </div>
            </div>

            <div className={`tab-content ${contentStyle('debit-orders')}`} id='debit-orders'>
              <div className='col-md-12'>
                <h3>Debit orders</h3>
                <DebitOrdersListing debitOrders={customer.scoreCollectionTransactions} />
              </div>
            </div>

            <div className={`tab-content ${contentStyle('unpaids')}`} id='unpaids'>
              <div className='col-md-12'>
                <h3>Unpaid Debit orders</h3>
                <UnpaidDebitOrdersListing unpaids={customer.scoreUnpaidCollectionTransactions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

const CustomerDetailsWrapper= ({ customerId }) => {
  const store = new CustomerStore(customerId);

  return(
    <ErrorBoundary>
      <CustomerDetails store={store} />
    </ErrorBoundary>
  )
}

export default CustomerDetailsWrapper;
