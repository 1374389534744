import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  labelClassNames: PropTypes.string,
  spanClassNames: PropTypes.string,
}

const defaultProps = {
  labelClassNames: 'switch',
  spanClassNames: 'slider'
}

const ToggleSwitch = ({labelClassNames, spanClassNames, onChange, value}) => {
  return(
    <label className={labelClassNames}>
      <input type='checkbox' value={value} onChange={onChange} />
      <span className={spanClassNames}></span>
    </label>
  )
}

ToggleSwitch.defaultProps = defaultProps;
ToggleSwitch.propTypes = propTypes;
export default ToggleSwitch;
