import React, {useState} from 'react';
import { ToastContainer } from 'react-toastify';

import AccommodationOptionsStore from '../stores/AccommodationOptionsStore';
import AccommodationOptionsListing from './AccommodationOptionsListing';
import TypeSelect from '../TypeSelect';

const AccommodationOptionsWrapper = ({ accommodationName, accommodationId }) => {
  const [activeAccommodationId, setActiveAccommodationId] = useState(accommodationId)
  const [activeAccommodationName, setActiveAccommodationName] = useState(accommodationName)

  const onAccommodationSelected = accommodation => {
    setActiveAccommodationName(accommodation.name)
    setActiveAccommodationId(accommodation.id)
  }

  const store = new AccommodationOptionsStore({ accommodationId: activeAccommodationId })

  return (
    <div>
      <p>Listing Room options for: {activeAccommodationName}</p>
      <TypeSelect
        label='Search for another Accommodation'
        fetchUrl={'/accommodations.json'}
        setSelected={onAccommodationSelected} />
      <AccommodationOptionsListing store={store} />
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default AccommodationOptionsWrapper;
