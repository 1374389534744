import React, {Fragment, useContext} from 'react';
import {WithWizard} from 'react-albus';
import {NavLink} from 'react-router-dom';

import {WizardContext} from './RegistrationWizard';
import {isPresent} from "../utils/objects";

const TitleBar = () => {
 const wizardLabels = useContext(WizardContext);
 return(
   <WithWizard
     render={({ next, previous, step, steps }) => (
       isPresent(step.id) ?
       <div id="titlebar" className="gradient">
         <div className="container">
           <div className="row">
             <div className="col-md-12">
               <Fragment>
                 <h1>{wizardLabels[step.id].pageHeading}</h1>
                 <nav id="breadcrumbs" className="wizard">
                   <ul>
                     <li><a href={'/registrations/wizard/instructions'}>Instructions</a></li>
                     <li><NavLink to={wizardLabels.membership.path}>Membership</NavLink></li>
                     <li><NavLink to={wizardLabels.biographical.path}>Biographical</NavLink></li>
                     <li><NavLink to={wizardLabels.address.path}>Address</NavLink></li>
                     <li><NavLink to={wizardLabels.banking.path}>Banking</NavLink></li>
                   </ul>
                 </nav>
               </Fragment>
             </div>
           </div>
         </div>
       </div>
       :
       <div></div>
     )}
   />
 )
}

export default TitleBar;
