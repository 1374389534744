import { makeAutoObservable, runInAction } from 'mobx';

import registrationWizardTransports from '../transports/registrationWizardTransports';

class RegistrationWizardStore {
  customerId = null
  submitting = false
  registrationSuccess = false
  packageCategories = []
  banks = []
  bankAccountTypes = []
  errors = []

  membership = {
    packageCategoryId: '',
    businessCustomer: false,
    referralCode: ''
  }
  biographical = {
    southAfrican: true,
    idNumber: '',
    cellphone: '',
    email: '',
    firstName: '',
    surname: '',
    initials: ''
  }
  address = {
    address: '',
    addressSuburb: '',
    addressTown: '',
    province: '',
    postalCode: ''
  }
  banking = {
    bankName: '',
    bankAccountNumber: '',
    bankAccountType: '',
    debitOrderDay: '',
    signatureValue: ''
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.actionDispatcher = registrationWizardTransports;
    this.loadFromSession();
    this.loadRegistrationData();
  }

  setAttribute(page, attribute, value){
    runInAction(() => {
      this[page][attribute] = value;
    });

    this.storeToSession();
  }

  setField(name, nextVal){
    runInAction(() => {
      this[name] = nextVal
    })
  }

  setRegistrationData(data){
    runInAction(() => {
      this.packageCategories = data.packageCategories
      this.banks = data.banks
      this.bankAccountTypes = data.bankAccountTypes
    })

    sessionStorage.setItem('registrationData', JSON.stringify(data))
  }

  loadRegistrationData(){
    const sessionData = sessionStorage.getItem('registrationData');
    if(sessionData){
      const jsonData = JSON.parse(sessionData);
      this.setRegistrationData(jsonData)
    } else {
      this.actionDispatcher.loadRegistrationData(this.setRegistrationData);
    }
  }

  loadFromSession(){
    const data = sessionStorage.getItem('pageData')
    if(data){
      const jsonData = JSON.parse(data);
      runInAction(() => {
        this.membership = jsonData.membership
        this.biographical = jsonData.biographical
        this.address = jsonData.address
        this.banking = jsonData.banking
      })
    }
  }

  storeToSession(){
    const data = {
      membership: this.membership,
      biographical: this.biographical,
      address: this.address,
      banking: this.banking
    }

    sessionStorage.setItem('pageData', JSON.stringify(data))
  }

  save(){
    this.setField('submitting', true);
    runInAction(() => {
      this.actionDispatcher.saveRegistration(this)
    })
  }

  prettifyMembership(){
    const {membership} = this;
    return {
      'Membership package' : membership.packageCategoryId,
      'Business membership': membership.businessCustomer ? 'Yes' : 'No',
      'Referral code': membership.referralCode ? membership.referralCode : 'None'
    }
  }

  prettifyBiographical(){
    const {biographical} = this;
    return {
      'South African': biographical.southAfrican ? 'Yes': 'No',
      'ID/Passport Number': biographical.idNumber,
      'Cellphone': biographical.cellphone,
      'Email': biographical.email,
      'First name': biographical.firstName,
      'Surname': biographical.surname,
      'Initials': biographical.initials
    }
  }

  prettifyAddress(){
    const {address} = this;
    return {
      'Address': address.address,
      'Suburb': address.addressSuburb,
      'City/Town': address.addressTown,
      'Province': address.province,
      'Postal code': address.postalCode
    }
  }

  prettifyBanking(){
    const {banking} = this;
    return {
      'Bank name': banking.bankName,
      'Account number': banking.bankAccountNumber,
      'Account type': banking.bankAccountType,
      'Debit order day': banking.debitOrderDay,
      'Signature': banking.signatureValue
    }
  }
}

export default RegistrationWizardStore
