import {gql} from '@apollo/client'

export const REGISTRATION_DATA_QUERY = gql`
    query registrationData {
        packageCategories {
            id
            name
            price
        }
        banks
        bankAccountTypes {
            id
            name
            scoreIdentifier
        }
    }
`;
