import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react';
import { ToastContainer } from 'react-toastify';

import AccommodationOptionsStore from '../stores/AccommodationOptionsStore';
import AccommodationOptionRatesListing from './AccommodationOptionRatesListing';
import Spinner from '../Spinner';
import ToggleSwitch from '../ToggleSwitch';
import CreateRate from './CreateRate';
import {accommodationOptionsUrl} from '../utils/routes/adminAccommodationRoutes';


const EditAccommodationOption = observer(({ store }) => {
  const [showRates, setShowRates] = useState(false)
  const option = store.accommodationOption;
  const accommodation = store.accommodationWithOptions;

  const changeOptionValue = (attrib, value) => {
    store.updateAccommodationOptionProperty(attrib, value)
  }

  if(option) {
    return (
      <div>
        <section className={'row'}>
          { accommodation && <p><a href={accommodationOptionsUrl(accommodation.slug)}>{accommodation.name}</a>/{option.roomName}</p> }
          <h3>Accommodation Option Details</h3>
          <div className={'col-md-3'}>
            <label>Room Name</label>
            <input value={option.roomName}
                   onChange={(e) => { changeOptionValue('roomName', e.target.value)}}/>
          </div>
          <div className={'col-md-3'}>
            <label>Room Type</label>
            <input value={option.roomType}
                   onChange={(e) => { changeOptionValue('roomType', e.target.value)}}/>
          </div>
          <div className={'col-md-3'}>
            <label>Max Occupancy</label>
            <input value={option.maxOccupancy}
                   onChange={(e) => { changeOptionValue('maxOccupancy', parseInt(e.target.value))}}
                   type='number' />
          </div>
        </section>
        <button className='button' onClick={store.updateAccommodationOption}>Save</button>

        <p>
          <span>{`${showRates ? 'Hide' : 'Show'}`} Rates</span>
          <ToggleSwitch onChange={() => { setShowRates(!showRates) }} value={showRates} spanClassNames={'slider round'} />
        </p>
        <br />
        { showRates &&
          <React.Fragment>
            <CreateRate store={store} />
            <AccommodationOptionRatesListing rates={option.rates} />
          </React.Fragment>
        }
      </div>
    )
  }

  return <Spinner size={'large'} />
});

const EditAccommodationOptionWrapper = ({ accommodationOptionId, accommodationId }) => {
  const store = new AccommodationOptionsStore({ accommodationOptionId, accommodationId })
  return(
    <Fragment>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <EditAccommodationOption store={store} />
    </Fragment>
  )
}

export default EditAccommodationOptionWrapper;
