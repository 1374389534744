import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react';
import Modal from 'react-modal';

import CreateRateForm from './CreateRateForm';
import {modalMedium} from '../utils/styles/modalStyles';

Modal.setAppElement('#reactModalAnchor');

const CreateRate = observer(({ store }) => {
  const [formVisible, setFormVisible] = useState(false);
  return(
    <Fragment>
      <button onClick={() => { setFormVisible(true) }} className='button'>Add Rate</button>
      <Modal isOpen={formVisible} style={modalMedium}>
        <CreateRateForm store={store} setFormVisible={setFormVisible} />
      </Modal>
    </Fragment>
  )
})

export default CreateRate;
