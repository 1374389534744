import React, {useState} from 'react';
import {observer} from 'mobx-react';

import Input from '../Input';

const CreateDeductionForm = observer( ({ store, setFormVisible }) => {
  const [deductionDate, setDeductionDate] = useState(store.activeDeduction.deductionDate);
  const [amount, setAmount] = useState(store.activeDeduction.amount);
  const [description, setDescription] = useState(store.activeDeduction.description);

  const [deductionDateError, setDeductionDateError] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const isSubmissionValid = () => {
    let clear = true;

    if(deductionDate === ''){
      clear = false;
      setDeductionDateError('Deduction date invalid, a value is required');
    }
    if(amount === undefined || amount < 1){
      clear = false;
      setAmountError('Amount invalid, must be greater than 0');
    }
    if(description === ''){
      clear = false;
      setDescriptionError('Description invalid, a value is required');
    }

    return clear;
  }

  const submitDeduction = () => {
    if(isSubmissionValid()) {
      store.saveDeduction();
      quitEdit();
    }
  }

  const updateAttribute = (attribute, value, callback) => {
    callback(value);
    store.setActiveDeductionAttribute(attribute, value);
  }

  const resetForm = () => {
    setDescription('');
    setAmount(0);
    setDeductionDate('');

    setDescriptionError(null);
    setAmountError(null);
    setDeductionDateError(null);

    store.resetActiveDeduction(null);
  }

  const quitEdit = () => {
    resetForm();
    setFormVisible(false);
  }

  return(
    <div>
      <div className='row'>
        <h3>Create Points Deduction</h3>
        <div className='col-md-6'>
          <Input onChange={(e) => {updateAttribute('deductionDate', e.target.value, setDeductionDate)}}
                 value={deductionDate}
                 name='deductionDate'
                 type='date'
                 label='Deduction date'
                 error={deductionDateError}
          />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => {updateAttribute('amount', e.target.value, setAmount)}}
                 value={amount}
                 name='amount'
                 type='number'
                 label='Amount'
                 error={amountError}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <Input
            onChange={(e) => {updateAttribute('description', e.target.value, setDescription)}}
            value={description}
            name='description'
            type='text'
            label='Description'
            error={descriptionError}
          />
          <br />
          <button onClick={quitEdit} className='border button'>Cancel</button>
          <button onClick={submitDeduction} className='button'>Save</button>
        </div>
      </div>
    </div>
  )
});

export default CreateDeductionForm;
