export const singleRowModal = {
  content: {
    height: '200px',
    width: '200px',
  }
}

export const modalSmall = {
  content: {
    height: '300px',
    width: '300px',
  }
}

export const modalMedium = {
  content: {
    height: '50%',
    width: '50%',
  }
}
