import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  size: PropTypes.string.isRequired,
};

const Spinner = ({ size }) => {
  return <div className={`spinner ${size}`} />;
};

Spinner.propTypes = propTypes;
export default Spinner;
