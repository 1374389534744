import React, {useState} from 'react';
import {observer} from 'mobx-react';

import AccommodationsListing from './AccommodationsListing';
import AccommodationsStore from './../stores/AccommodationsStore';
import { newAccommodationUrl } from '../utils/routes/adminAccommodationRoutes';

const AccommodationsManagement = observer( ({ store })=> {
  const [activeAccommodationName, setActiveAccommodationName] = useState('');


  const searchAgain = (e) => {
    setActiveAccommodationName(e.target.value)
    store.searchAccommodations(activeAccommodationName)
  }

  return (
    <div>
      <p>Type to search</p>
      <input type={'search'} name={'accommodationName'} onChange={(e) => searchAgain(e)} />
      <a href={newAccommodationUrl()} className='underlined blue-link'>Create New</a>
      <AccommodationsListing accommodations={store.accommodationsList} />
    </div>
  )
})


const AccommodationsWrapper = () => {
  const store = new AccommodationsStore();
  return <AccommodationsManagement store={store} />
}

export default AccommodationsWrapper;
