import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';

const link = new HttpLink({
  uri: '/graphql',
  headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link
});

export default client;
