import React from 'react';

const ToggleSelect = ({ selected, icon, label, onClick }) => {
  return(
    <span className={ selected ? 'option-selected' : 'option-unselected' } onClick={onClick}>
      <i className={`im ${icon}`}></i> {label}
    </span>
  )
}

export default ToggleSelect;
