import React, {Fragment} from 'react';

import Input from '../Input';

const GuestUser = ({ firstName, lastName, email, cellphone, setFirstName, setLastName, setEmail, setCellphone, resolveError }) => {
  return(
    <Fragment>
      <div className="col-md-6 min-height-130">
        <Input
          onChange={(e) => { setFirstName(e.target.value) }}
          value={firstName}
          name='firstName'
          type='text'
          required={true}
          label='First Name'
          error={resolveError('firstName')}
          classNames={resolveError('firstName') ? 'error-showing' : '' }
        />
      </div>

      <div className="col-md-6 min-height-130">
        <Input
          onChange={(e) => { setLastName(e.target.value) }}
          value={lastName}
          name='lastName'
          type='text'
          required={true}
          label='Surname'
          error={resolveError('lastName')}
          classNames={resolveError('lastName') ? 'error-showing' : '' }
        />
      </div>

      <div className="col-md-6 min-height-130">
        <div className="input-with-icon medium-icons">
          <Input
            onChange={(e) => { setEmail(e.target.value) }}
            value={email}
            name='email'
            type='email'
            required={true}
            label='E-Mail Address'
            iconClasses='im im-icon-Mail'
            error={resolveError('email')}
            classNames={resolveError('email') ? 'error-showing' : '' }
          />
        </div>
      </div>

      <div className="col-md-6 min-height-130">
        <div className="input-with-icon medium-icons">
          <Input
            onChange={(e) => { setCellphone(e.target.value) }}
            value={cellphone}
            name='cellphone'
            type='phone'
            required={true}
            label='Phone'
            iconClasses='im im-icon-Phone-2'
            error={resolveError('cellphone')}
            classNames={resolveError('cellphone') ? 'error-showing' : '' }
          />
        </div>
      </div>
    </Fragment>
  )
}

export default GuestUser;
