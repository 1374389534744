import React, {useContext} from 'react';

import {WizardContext} from './RegistrationWizard';

const RegistrationStep = ({id, children}) => {
  const wizardLabels = useContext(WizardContext);
  const step = wizardLabels[id];

  return(
    <div className="add-listing-section margin-top-10">
      <div className="add-listing-headline">
        <h3><i className={step.iconClasses}></i> {step.sectionHeading}</h3>
      </div>

      <div className="submit-section">
        <div className="row with-forms">
          {children}
        </div>
      </div>
    </div>
  )
}

export default RegistrationStep;
