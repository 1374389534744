import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("connected to filter controller")
  }

  search() {
    console.log("searching...")
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 200)
  }
}
