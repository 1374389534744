import {REGISTRATION_DATA_QUERY} from '../queries/registrations';
import {SAVE_REGISTRATION_MUTATION} from '../mutations/registrations';
import client from '../utils/client';

const saveRegistration = (store) => {
  const args = prepareRegistrationArg(store)
  client.mutate({ mutation: SAVE_REGISTRATION_MUTATION, variables: args})
    .then(({data}) => {
      if(data && data.saveRegistration){
        const result = data.saveRegistration;
        if(result.errors.length > 0){
          store.setField('errors', result.errors);
        } else {
          store.setField('customerId', result.customer.id)
          store.setField('registrationSuccess', true);
        }
      }
    })
    .catch(error => console.error(error))
    .finally(() => { store.setField('submitting', false); })
}

const loadRegistrationData = (callback) => {
  client.query({query: REGISTRATION_DATA_QUERY })
    .then(({data}) => {
      if(data){
        callback(data)
      } else {
        console.error(data)
      }
    })
    .catch(error => console.error(error))
}

const prepareRegistrationArg = store => {
  const { membership, biographical, address, banking } = store
  return { membership, biographical, address, banking }
}

export default  {
  saveRegistration,
  loadRegistrationData
}
