import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  labelClassNames: PropTypes.string,
  errorClasses: PropTypes.string,
  required: PropTypes.bool,
  iconClasses: PropTypes.string,
  options: PropTypes.array,
}

const defaultProps = {
  required: true,
  errorClasses: 'error',
}

const Input = ({type, name, onChange, label, value, error, classNames, labelClassNames, errorClasses, iconClasses, inputProps, required = false, options }) => {
  return(
    <Fragment>
      { label &&
        <label htmlFor={name} className={labelClassNames}>
          {required && <abbr title='required'>*</abbr>}
          {label}
        </label>
      }
      { type === 'select' ?
        selectInput({name, onChange, value, classNames, required, options, inputProps})
        :
        baseInput({type, name, onChange, value, classNames, required, inputProps}) }
      { iconClasses && <i className={iconClasses}></i>}
      { error && <span className={errorClasses}>{error}</span> }
    </Fragment>
  )
}

const baseInput = ({type, name, onChange, value, classNames, required, inputProps}) => {
  return(
    <input
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      className={classNames}
      required={required}
      { ...inputProps }
    />
  )
}

const selectInput = ({name, onChange, value, classNames, required, options, inputProps}) => {
  return(
    <select name={name} onChange={onChange} value={value} className={classNames} required={required} { ...inputProps} >
      { options }
    </select>
  )
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
export default Input;
