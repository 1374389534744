import {CUSTOMER_BALANCE_AUDIT_PAGE_QUERY} from '../queries/customers';
import {
  DELETE_DEDUCTION_MUTATION,
  REFRESH_WALLET_MUTATION,
  SAVE_DEDUCTION_MUTATION,
  UPDATE_BILLING_ACCOUNT_MUTATION
} from '../mutations/customers';
import client from '../utils/client';
import {isPresent} from '../utils/objects';

const fetchCustomer = ({customerId, callback, errorCallback}) => {
  client.query({ query: CUSTOMER_BALANCE_AUDIT_PAGE_QUERY, variables: { id: customerId }, fetchPolicy: 'network-only'})
    .then(({data}) => {
      if(data && data.customer){
        callback(data.customer)
      } else {
        console.error(data)
        errorCallback()
      }
    })
    .catch((error) => {
      errorCallback();
      console.error(error);
    })
}

const saveDeduction = (deduction, walletId, callback) => {
  const vars = prepareDeductionForSave(deduction, walletId)
  client.mutate({ mutation: SAVE_DEDUCTION_MUTATION, variables: vars })
    .then(() => callback())
    .catch(errors => console.error(errors))
}

const deleteDeduction = (deductionId, callback) => {
  client.mutate( { mutation: DELETE_DEDUCTION_MUTATION, variables: { id: deductionId }})
    .then(() => callback())
    .catch(errors => console.error(errors))
}

const updateBillingAccount = (deduction, callback) => {
  const { id, startDate, endDate, endDateReason } = prepareBillingAccountForSave(deduction)

  client.mutate({ mutation: UPDATE_BILLING_ACCOUNT_MUTATION, variables: { id, startDate, endDate, endDateReason} })
    .then(() => callback())
    .catch(errors => console.error(errors))
}

const refreshWallet = (customerId, callback) => {
  client.mutate({ mutation: REFRESH_WALLET_MUTATION, variables: { customerId }})
    .then(() => callback())
    .catch(errors => console.error(errors))
}

const prepareDeductionForSave = (deduction, walletId) => {
  return {
    id: deduction.id,
    walletId: deduction.getawayPointsWalletId ? deduction.getawayPointsWalletId : walletId,
    amount: isPresent(deduction.amount) ? parseFloat(deduction.amount) : deduction.amount,
    description: deduction.description,
    deductionDate: deduction.deductionDate
  }
}

const prepareBillingAccountForSave = (billingAccount) => {
  return {
    id: billingAccount.id,
    startDate: billingAccount.startDate,
    endDate: isPresent(billingAccount.endDate) ? billingAccount.endDate : null,
    endDateReason: isPresent(billingAccount.endDateReason) ? billingAccount.endDateReason : null,
  }
}

export default {
  fetchCustomer,
  saveDeduction,
  refreshWallet,
  deleteDeduction,
  updateBillingAccount
}
