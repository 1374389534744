import {makeAutoObservable, runInAction} from 'mobx';

import accommodationTransports from '../transports/accommodationTransports';

class AccommodationsStore {
  accommodationsList = [];
  actionDispatcher;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.actionDispatcher = accommodationTransports;
    this.searchAccommodations('')
  }

  setAccommodationsList(list){
    runInAction(()=> {
      this.accommodationsList = list;
    })
  }

  searchAccommodations(q){
    this.actionDispatcher.accommodationSearch(q, (list) => this.setAccommodationsList(list))
  }
}

export default AccommodationsStore;
