import React, {Component} from 'react'
import Tree from '@naisutech/react-tree'

class TeamBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: {
        error: false,
        loaded: false,
      },
      data: null
    };

    this.loadTeam = this.loadTeam.bind(this);
  }

  componentDidMount() {
    this.loadTeam()
  }

  loadTeam(){
    fetch('/dashboard/business/fetch_team.json')
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ loading: { error: false, loaded: true }, data: result.team })
        },
        (error) => {
          this.setState({ loading: { error: true, loaded: false, data: null }})
        }
      )

  }


  render() {
    const { data, loading: { error, loaded } } = this.state;

    if(loaded) {
      return (<Tree nodes={data} onSelect={() => {}} />)
    }

    if(error){
      return(
        <div className="notification notice">
          There was an error fetching your team, please refresh the page to try again
        </div>
      )
    }

    return(
      <div className="notification notice">
        Waiting to load team...
      </div>
    )
  }
}

export default TeamBrowser;

