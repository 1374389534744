import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  labelClassNames: PropTypes.string,
  errorClasses: PropTypes.string,
  required: PropTypes.bool,
}

const defaultProps = {
  required: true,
  errorClasses: 'error',
}

const TextArea = ({ name, onChange, label, value, error, classNames, labelClassNames, errorClasses, required }) => {
  return(
    <Fragment>
      { label && <label htmlFor={name} className={labelClassNames}>{label}</label> }
      <textarea name={name} onChange={onChange} value={value} className={classNames} required={required}/>
      { error && <span className={errorClasses}>{error}</span> }
    </Fragment>
  )
}

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;
export default TextArea;
