import React from 'react';

const GetawayPointMovementsListing = ({ movements }) => {
  const rows = movements.map((mov) => {
    return(
      <tr key={`movement-${mov.id}`}>
        <td>{mov.transactionDate}</td>
        <td>{mov.amount}</td>
        <td>{mov.description}</td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
}

export default GetawayPointMovementsListing;
