import React from 'react';

const BusinessMemberships = ({customerId, businessMemberships}) => {
  const rows = businessMemberships.map(bm => {
    return(
      <tr key={`business-membership-${bm.id}`}>
        <td>{bm.id}</td>
        <td>{bm.startDate}</td>
        <td>{bm.endDate}</td>
        <td><a href={`/admin/admin_billing_accounts/${customerId}/edit`}>Edit</a></td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>id</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
}

export default BusinessMemberships;
