import {gql} from '@apollo/client'

export const ACCOMMODATION_WITH_OPTIONS_QUERY = gql`
    query accommodation($accommodationId: ID!, $currentOnly: Boolean){
        accommodation(id: $accommodationId){
            id
            name
            slug
            accommodationOptions {
                id
                roomName
                roomType
                maxOccupancy
                accommodationId
                rates(currentOnly: $currentOnly) {
                    id
                    baseRate
                    rackRate
                    currency
                    startDate
                    endDate
                    rateType
                }
            }
        }
    }
`

export const ACCOMMODATIONS_SEARCH = gql`
    query accommodations($name: String){
        accommodationSearch(name: $name){
            id
            slug
            name
            location {
                name
                slug
            }
        }
    }
`

export const ACCOMMODATION_OPTION_QUERY = gql`
    query accommodationOption($id: ID!){
        accommodationOption(id: $id){
            id
            roomName
            roomType
            maxOccupancy
            accommodationId
            rates {
                id
                baseRate
                rackRate
                currency
                startDate
                endDate
                rateType
            }
        }
    }
`
