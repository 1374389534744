import React, {useState} from 'react';

import client from '../utils/client';
import {CREATE_QUICK_BOOKING_REQUEST_MUTATION} from '../mutations/quick_booking_requests';
import LoggedInUser from './LoggedInUser';
import GuestUser from './GuestUser';
import TextArea from '../TextArea';

const QuickBookingRequestForm = ({loggedInUser, siteKey, type, slug}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [details, setDetails] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [buttonText, setButtonText] = useState('Submit');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const appendSiteAndTypeToDetails = () => {
    if(details === '') return details

    return `${details}\nType: ${type}\nSlug: ${slug}`
  }

  const userDetails = () => {
    if(loggedInUser) return { ...loggedInUser }
    return { firstName, lastName, cellphone, email }
  }

  const submit = (e) => {
    e.preventDefault();

    setButtonText('Submitting...');
    setDisableSubmit(true);

    const submissionDetails = appendSiteAndTypeToDetails(details)
    grecaptcha.ready(function() {
      grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
        client.mutate({ mutation: CREATE_QUICK_BOOKING_REQUEST_MUTATION,
          variables: { ...userDetails(), token, details: submissionDetails }, client: client })
          .then(({data}) => {
            const { bookingRequest, errors } = data.createQuickBookingRequest
            if(bookingRequest && bookingRequest.id !== null && bookingRequest.id !== undefined){
              setButtonText('Submit');
              setDisableSubmit(false);
              setSubmitted(true);
            }

            if(errors !== null){
              console.error(errors)
              setValidationErrors(errors);
              setButtonText('Submit');
              setDisableSubmit(false);
            }

          }).catch((error) => {
            console.error(error)
            setButtonText('Submit');
            setDisableSubmit(false);
        })
      });
    });
  }

  const resolveError = (field) => {
    const issue = validationErrors.find(item => item.field === field);
    if (issue) return issue.message

    return null
  }

  const form = () => {
    const inputs = loggedInUser !== null ? <LoggedInUser user={loggedInUser} /> :
      <GuestUser
        firstName={firstName}
        lastName={lastName}
        email={email}
        cellphone={cellphone}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setCellphone={setCellphone}
        errors={validationErrors}
        resolveError={resolveError}
      />

    return(
      <form onSubmit={submit}>
        { inputs }
        <div className="col-lg-12">
          <TextArea
            value={details}
            name='details'
            onChange={(e) => { setDetails(e.target.value) }}
            label='Tell us more about your trip. Include checkin and checkout date, number of guests, destination
            and/or hotel or package name'
            required={true}
            error={resolveError('details')}
            classNames={resolveError('details') ? 'error-showing' : '' }
          />
        </div>
        <div className="col-lg-12">
          <button type='submit' className='submit button' disabled={disableSubmit}>{buttonText}</button>
        </div>
      </form>
    )
  }

  const doneSubmit = () => {
    return(
      <div className="col-lg-12 padding-right-30">
        <div className="notification success large margin-bottom-55">
          <p className="margin-bottom-5">
            Your Booking request was submitted successfully. We will be in touch soon
            <br />
            Click <button className='link-button' onClick={() => { history.go(-1)} }>here</button> to go back
          </p>
        </div>
      </div>
    )
  }

  return(
    <div className='row'>
      { submitted ? doneSubmit() : form() }
    </div>
  )
}

export default QuickBookingRequestForm;
