import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  localData: PropTypes.array,
  preselectedValue: PropTypes.string
}

const defaultProps = {
  preselectedValue: ''
}

class GenericTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTray: false,
      selectedOption: props.preselectedValue,
    }
  }

  onChangeInput = async (e) => {
    const { value } = e.target;

    this.setState({ selectedOption: value })
  }

  onClickItemSpan = (event, item) => {
    const { innerText } = event.target;
    const { props: { setSelected } } = this;

    this.setState({ selectedOption: innerText, showTray: false }, setSelected(item));
  }

  render() {
    const { state: { showTray, selectedOption }, props: { label, localData } } = this;
    const content = localData.map((item, i) => {
      return (
        <span key={`select-item-${i}`}
          onClick={(e) => this.onClickItemSpan(e, item) }>
          {item}
        </span>
      )
    })

    return(
      <div className='fixed-width'>
        <label htmlFor='name-control'>{label}</label>
        <input id='name-control'
               onFocus={() => { this.setState({ showTray: true }) }}
               onChange={this.onChangeInput} value={selectedOption}
               type={'search'}
        />
        {showTray &&
          <div id='list-wrapper' className='dropdown'>
            {content}
          </div>
        }
      </div>
    )
  }
}

GenericTypeSelect.propTypes = propTypes;
GenericTypeSelect.defaultProps = defaultProps;
export default GenericTypeSelect;
