import {gql} from '@apollo/client';

export const DELETE_DEDUCTION_MUTATION = gql`
  mutation deleteDeduction($id: ID!){
    deleteDeduction(id: $id){
      customer {
        name
        getawayPointsDeductions {
          id
          deductionDate
          amount
          description
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SAVE_DEDUCTION_MUTATION = gql`
  mutation saveDeduction($id: ID, $walletId: ID!, $amount: Float!, $deductionDate: Date!, $description: String!){
    saveDeduction(
      id: $id,
      walletId: $walletId,
      amount: $amount,
      deductionDate: $deductionDate,
      description: $description){
      getawayPointsDeduction{
        id
        deductionDate
        amount
        description
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_BILLING_ACCOUNT_MUTATION = gql`
  mutation updateBillingAccount($id: ID!, $startDate: Date, $endDate: Date, $endDateReason: String) {
    updateBillingAccount(
      id: $id,
      startDate: $startDate,
      endDate: $endDate,
      endDateReason: $endDateReason
    ){
      billingAccount {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const REFRESH_WALLET_MUTATION = gql`
  mutation refreshWallet($customerId: ID!){
    refreshWallet(customerId: $customerId){
      customer{
        id
      }
      errors {
        field
        message
      }
    }
  }
`
