import {makeAutoObservable, runInAction} from 'mobx';
import accommodationOptionsTransports from '../transports/accommodationOptionsTransports';

class AccommodationOptionsStore {
  accommodationId
  actionDispatcher
  accommodationOptionId
  accommodationOption
  accommodationWithOptions

  constructor({ accommodationOptionId, accommodationId }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accommodationId = accommodationId;
    this.accommodationOptionId = accommodationOptionId;
    this.actionDispatcher = accommodationOptionsTransports;

    this.fetchAccommodationWithOptions();
    this.fetchAccommodationOption();
  }

  fetchAccommodationWithOptions(){
    if(this.accommodationId === null || this.accommodationId === undefined) return

    this.actionDispatcher.fetchAccommodationWithOptions(this.accommodationId, this.setAccommodationWithOptions)
  }

  setAccommodationWithOptions(accommodation){
    runInAction(() => {
      this.accommodationWithOptions = accommodation;
    })
  }

  setAccommodationOption(option){
    runInAction(() =>{
      this.accommodationOption = option;
    })
  }

  fetchAccommodationOption(){
    if(this.accommodationOptionId === null || this.accommodationOptionId === undefined) return

    this.actionDispatcher.fetchAccommodationOption(this.accommodationOptionId, this.setAccommodationOption)
  }

  updateAccommodationOptionProperty(property, nextVal){
    runInAction(() => {
      this.accommodationOption[property] = nextVal
    })
  }

  createAccommodationOption({ roomName, roomType, maxOccupancy}){
    this.actionDispatcher.saveAccommodationOption({
      roomName,
      roomType,
      maxOccupancy,
      accommodationId: this.accommodationId
    }, () => {})
  }

  updateAccommodationOption(){
    this.actionDispatcher.saveAccommodationOption(this.accommodationOption, this.setAccommodationOption)
  }

  addRate(rate){
    runInAction(() => {
      this.accommodationOption['rates'].push(rate);
    })
  }
}

export default AccommodationOptionsStore;
