import React from 'react'

const ScoreCollectionsListing = ({listings}) => {
  const rows = listings.map((listing) => {
    return(
      <tr key={`score-client-collection-${listing.id}`}>
        <td>{listing.id}</td>
        <td>{listing.commencementDate}</td>
        <td>{listing.amount}</td>
        <td>{listing.status}</td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
      <tr>
        <th>id</th>
        <th>Start date</th>
        <th>Collection Amount</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
}

export default ScoreCollectionsListing;
