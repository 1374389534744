import React from 'react';
import { observer } from 'mobx-react';

import Input from '../Input';
import RegistrationStep from './RegistrationStep';
import ToggleSelect from '../ToggleSelect';

const Membership = observer(({id, membership, setAttribute, packageCategories}) => {
  const setReferralCodeFromCookie = () => {
    const cookieString = document.cookie
      .split('; ')
      .find(row => row.startsWith('referral_code='))

    if(cookieString){
      const cookie = cookieString.split('=')[1];
      setAttribute(id, 'referralCode', cookie)
    }
  }

  setReferralCodeFromCookie();

  const categoryIcon = name => {
    switch (name){
      case 'Starter':
        return 'im-icon-Suitcase'
      case 'Comfort':
        return 'im-icon-Umbrella-3'
      case 'Explorer':
        return 'im-icon-Globe'
      default:
        return ''
    }
  }

  const categoryOptions = packageCategories.map((category) => {
    return(
      <ToggleSelect
        key={`category-select-${category.id}`}
        onClick={() => setAttribute(id, 'packageCategoryId', category.id)}
        label={`${category.name} R${category.price}.00`}
        selected={membership.packageCategoryId === category.id}
        icon={categoryIcon(category.name)}
      />
    )
  })

  return(
    <RegistrationStep id={id}>
      <fieldset>
        <div className="col-md-12">
          <label htmlFor={'packageCategory'}>Please choose a package</label>
          <div className="margin-bottom-20">
            {categoryOptions}
          </div>
        </div>

        <div className="col-md-12 margin-top-40">
          <label htmlFor="business_customer">Do you want to join the business programme?</label>
          <ToggleSelect icon={'im-icon-Yes'} selected={membership.businessCustomer} label={'Yes, please'}
                        onClick={() => setAttribute(id, 'businessCustomer', true)} />
          <ToggleSelect icon={'im-icon-Close-Window'} selected={!membership.businessCustomer} label={'No, thank you'}
                        onClick={() => setAttribute(id, 'businessCustomer', false)} />
          {
            membership.businessCustomer ?
              <p className="margin-bottom-20 margin-top-20 notification notice">NOTE: A monthly business operation fee of R100 applies</p>
              :
              <p className="margin-bottom-20 margin-top-20 notification notice">You have opted not to join the business programme</p>
          }

        </div>

        <div className="col-md-6">
          <Input onChange={(e) => setAttribute(id, 'referralCode', e.target.value)}
                 name={'referralCode'} type={'text'}
                 value={membership.referralCode}
                 label={'Referral code'}
                 required={false}
          />
        </div>
      </fieldset>
    </RegistrationStep>
  )
});

export default Membership;
