import React, {Component} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  packagePath: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  validity: PropTypes.string.isRequired,
  numberOfGuests: PropTypes.number.isRequired,
  wishListed: PropTypes.bool.isRequired,
  hideColStyles: PropTypes.bool,
}

class PackageSummary extends Component {
  render() {
    const { packagePath, imageUrl, price, title, locationName, validity, numberOfGuests, wishListed, hideColStyles } = this.props
    return(
      <div className={`${hideColStyles ? '' : 'col-lg-4 col-md-4 ' } margin-bottom-70`}>
        <a href={packagePath} >
          <div className="blog-compact-item">
            <img src={imageUrl} alt="Package display image" />
            <span className="blog-item-tag now-open">Package</span>
            <div className="blog-compact-item-content with-background padding-top-15 padding-bottom-15">
              <ul className="blog-post-tags">
                <li>{price}</li>
              </ul>
              <h3>{title}</h3>
              <span>In {locationName}</span><br/>
              <span>{validity}</span><br/>
              <span>For {numberOfGuests} travellers</span><br/>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

PackageSummary.propTypes = propTypes;
export default PackageSummary;
