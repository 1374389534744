import React from 'react';

const UnpaidDebitOrdersListing = ({unpaids}) => {
  const rows = unpaids.map(debO => {
    return(
      <tr key={`debit-order-${debO.id}`}>
        <td>{debO.transactionDate}</td>
        <td>{debO.amount}</td>
        <td>{debO.unpaidReason}</td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>Transaction Date</th>
          <th>Amount</th>
          <th>Unpaid Reason</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
}

export default UnpaidDebitOrdersListing;
