import React from 'react';
import { observer } from 'mobx-react';

import Input from '../Input';
import RegistrationStep from './RegistrationStep';
import ToggleSelect from "../ToggleSelect";

const Biographical = observer(({ id, biographical, setAttribute }) => {
  return(
    <RegistrationStep id={id}>
      <fieldset>
        <label>
          <abbr title="required">*</abbr>
          Are you South African?
        </label>
        <div className='col-md-12 margin-top-10 checkboxes'>
          <ToggleSelect icon={'im-icon-Yes'} selected={biographical.southAfrican} label={'Yes, I am'}
                        onClick={() => setAttribute(id, 'southAfrican', true)} />
          <ToggleSelect icon={'im-icon-Close-Window'} selected={!biographical.southAfrican} label={'No, I am not'}
                        onClick={() => setAttribute(id, 'southAfrican', false)} />
        </div>
      </fieldset>
      <fieldset className='margin-top-20'>
        <div className='col-md-6'>
          <Input
            onChange={(e) => setAttribute(id, 'idNumber', e.target.value)}
            name={'idNumber'}
            type={'text'}
            label={'ID/Passport Number'}
            value={biographical.idNumber}
            required={true}/>
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'cellphone', e.target.value)}
                 name={'cellphone'} type={'text'} label={'Cellphone'} value={biographical.cellphone} required={true}/>
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'email', e.target.value)}
                 name={'email'} type={'email'} label={'Email'} value={biographical.email} required={true}/>
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'initials', e.target.value)}
                 name={'initials'} type={'text'} label={'Initials'} value={biographical.initials} required={true}/>
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'firstName', e.target.value)}
                 name={'firstName'} type={'text'} label={'First Name'} value={biographical.firstName} required={true}/>
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => setAttribute(id, 'surname', e.target.value)}
                 name={'surname'} type={'text'} label={'Surname'} value={biographical.surname} required={true}/>
        </div>
      </fieldset>
    </RegistrationStep>
  )
});

export default Biographical;
