import React, {useState} from 'react';
import {observer} from 'mobx-react';

import Input from '../Input';
import ToggleSwitch from '../ToggleSwitch';

const CreateRateForm = observer(({store, setFormVisible}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [baseRate, setBaseRate] = useState(0);
  const [rackRate, setRackRate] = useState(false);
  const [rateType, setRateType] = useState('SINGLE');

  const quitEdit = () => {
    resetForm();
    setFormVisible(false);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setBaseRate(0);
    setRackRate(false);
    setRateType('SINGLE');
  }

  const updateAttribute = (attribute, value, callback) => {
    callback(value)
  }

  const saveRate = () => {
    store.addRate({ startDate, endDate, baseRate, rackRate, rateType, 'currency': 'ZAR' })
    quitEdit();
  }

  return (
    <div>
      <h3>Create New Rate</h3>
      <div className='row'>
        <div className='col-md-6'>
          <Input onChange={(e) => { updateAttribute('startDate', e.target.value, setStartDate) }}
                 value={startDate}
                 name='startDate'
                 type='date'
                 label='Start date'
                 error={''}
          />
        </div>
        <div className='col-md-6'>
          <Input onChange={(e) => { updateAttribute('endDate', e.target.value, setEndDate) }}
                 value={endDate}
                 name='endDate'
                 type='date'
                 label='End date'
                 error={''}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Input onChange={(e) => { updateAttribute('baseRate', parseFloat(e.target.value), setBaseRate) }}
                 value={baseRate}
                 name='baseRate'
                 type='number'
                 label='Base rate'
                 error={''}
          />
        </div>
        <div className='col-md-6'>
          <Input
            onChange={(e) => { updateAttribute('baseRate', e.target.value, setRateType) }}
            name='rateType'
            type='select'
            options={[
              <option key='single'>SINGLE</option>,
              <option key='double'>DOUBLE</option>,
              <option key='child'>CHILD</option>
            ]}
            label='Type of rate'
            value={rateType}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <label>Rack Rate</label>
          <ToggleSwitch
            onClick={() => { updateAttribute('rackRate', !rackRate, setRackRate) }}
            selected={rackRate}
          />
        </div>
      </div>
      <button onClick={quitEdit} className='border button'>Cancel</button>
      <button onClick={saveRate} className='button'>Save</button>
    </div>
  )

});

export default CreateRateForm;
