import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  summary: PropTypes.shape({
    collectionId: PropTypes.string.isRequired,
    membershipFee: PropTypes.number.isRequired,
    paidCount: PropTypes.number.isRequired,
    unpaidCount: PropTypes.number.isRequired,
    pointsTotal: PropTypes.number.isRequired,

  }).isRequired
}
const PointsCalculationSummary = ({summary}) => {
  const collectionAmount = summary.membershipFee;
  const paidTotal = summary.paidCount * collectionAmount;
  const unpaidTotal = summary.unpaidCount * collectionAmount;
  const penaltyTotal = summary.unpaidCount * 50;
  const feeTotal = summary.paidCount * 30;

  return(
    <div className='points-summary'>
      <p className='summary-row'>
        <span>Total Paid:</span>
        <span>{summary.paidCount} x R{collectionAmount}</span>
        <span>R{paidTotal}</span>
      </p>
      <p className='summary-row'>
        <span>Total Unpaid: </span>
        <span>{summary.unpaidCount} x R{collectionAmount}</span>
        <span>R{unpaidTotal}</span>
      </p>
      <p className='summary-row'>
        <span>Unpaid Penalties:</span>
        <span>{summary.unpaidCount} x R50</span>
        <span>R{penaltyTotal}</span>
      </p>
      <p className='summary-row'>
        <span>Total Fees Paid: </span>
        <span>{summary.paidCount} x R30</span>
        <span>R{feeTotal}</span>
      </p>
      <p className='summary-row'>
        <span>Points Total: </span>
        <span>R{paidTotal} - R{feeTotal} - R{penaltyTotal}</span>
        <span>R{summary.pointsTotal}</span>
      </p>
    </div>
  )
}

PointsCalculationSummary.propTypes = propTypes;

export default PointsCalculationSummary;
