import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Wizard, Steps, Step} from 'react-albus';

import Address from './Address';
import Banking from './Banking';
import Biographical from './Biographical';
import Membership from './Membership';
import Navigation from './Navigation';
import TitleBar from './TitleBar';
import Review from './Review';
import RegistrationWizardStore from '../stores/RegistrationWizardStore';

const wizardLabels = {
  membership: {
    id: 'membership',
    pageHeading: 'Membership',
    sectionHeading: 'Membership',
    path: '/membership',
    iconClasses: 'sl sl-icon-globe',
  },
  biographical: {
    id: 'biographical',
    pageHeading: 'Biographical',
    sectionHeading: 'Personal Details',
    path: '/biographical',
    iconClasses: 'sl sl-icon-user',
  },
  address: {
    id: 'address',
    pageHeading: 'Address',
    sectionHeading: 'Address',
    path: '/address',
    iconClasses: 'sl sl-icon-location',
  },
  banking: {
    id: 'banking',
    pageHeading: 'Banking',
    sectionHeading: 'Banking',
    path: '/banking',
    iconClasses: 'sl sl-icon-credit-card',
  },
  review: {
    id: 'review',
    pageHeading: 'Review',
    sectionHeading: 'Review',
    path: '/review',
    iconClasses: 'sl sl-icon-book-open'
  }
}

export const WizardContext = React.createContext(wizardLabels)

const RegistrationWizard = () => {
  const store = new RegistrationWizardStore();

  return(
    <Router basename='registrations/wizard'>
      <Route render={({history}) => (
        <Wizard history={history}>
          <WizardContext.Provider value={wizardLabels}>
            <TitleBar />
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Steps>
                    <Step id={wizardLabels.membership.id}>
                      <Membership
                        id={wizardLabels.membership.id}
                        membership={store.membership}
                        setAttribute={store.setAttribute}
                        packageCategories={store.packageCategories}
                      />
                    </Step>
                    <Step id={wizardLabels.biographical.id}>
                      <Biographical id={wizardLabels.biographical.id} biographical={store.biographical} setAttribute={store.setAttribute}/>
                    </Step>
                    <Step id={wizardLabels.address.id}>
                      <Address id={wizardLabels.address.id} address={store.address} setAttribute={store.setAttribute}/>
                    </Step>
                    <Step id={wizardLabels.banking.id}>
                      <Banking
                        id={wizardLabels.banking.id}
                        banking={store.banking}
                        setAttribute={store.setAttribute}
                        banks={store.banks}
                        bankAccountTypes={store.bankAccountTypes}
                      />
                    </Step>
                    <Step id={wizardLabels.review.id}>
                      <Review id={wizardLabels.review.id} store={store} />
                    </Step>
                  </Steps>
                </div>
              </div>
            </div>
            <Navigation />
          </WizardContext.Provider>
        </Wizard>
      )}/>
    </Router>
  )
}

export default RegistrationWizard;
