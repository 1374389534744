import React from 'react';
import { observer } from 'mobx-react';

import EditBillingAccountForm from './EditBillingAccountForm';

const BillingAccountsListing = observer(({ store }) => {
  const rows = store.customer.billingAccounts.map(ba => {
    return(
      <tr key={`billing-account-${ba.id}`}>
        <td>{ba.id}</td>
        <td>{ba.startDate}</td>
        <td>{ba.endDate}</td>
        <td>
          <EditBillingAccountForm store={store} billingAccount={ba}/>
        </td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>id</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
});

export default BillingAccountsListing;
