import {gql} from '@apollo/client'

export const SAVE_REGISTRATION_MUTATION = gql`
    mutation saveRegistration($membership: membershipInput!, $biographical: biographicalInput!, $address: addressInput!, $banking: bankingInput!){
        saveRegistration(membership: $membership, biographical: $biographical, address: $address, banking: $banking){
            customer {
                id
                email
                firstName
            }
            errors {
                field
                message
                fullMessage
            }
        }
    }
`;
