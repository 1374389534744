import React, {Fragment} from 'react';
import { observer } from 'mobx-react';

import Input from '../Input';
import RegistrationStep from './RegistrationStep';
import GenericTypeSelect from '../GenericTypeSelect';
import ToggleSelect from "../ToggleSelect";

const Banking = observer(({id, banking, setAttribute, banks, bankAccountTypes}) => {
  const accountTypesOptions = bankAccountTypes.map((accountType) => {
    return(
      <ToggleSelect
        key={`account-type-select-${accountType.id}`}
        onClick={() => setAttribute(id, 'bankAccountType', accountType.name)}
        name={'bankAccountType'} label={accountType.name}
        selected={banking.bankAccountType === accountType.name}/>
    )
  })

  return(
    <Fragment>
      <RegistrationStep id={id}>
        <div className='col-md-12 notification notice margin-bottom-50'>
          <b>Debit order agreement with Asambeni Getaway gateways.</b>
        </div>

        <fieldset>
          <div className='col-md-6'>
            <GenericTypeSelect
              localData={banks}
              label={'Bank Name'}
              setSelected={(e) => setAttribute(id, 'bankName', e)}
              preselectedValue={banking.bankName}
            />
          </div>
          <div className='col-md-6 margin-bottom-50'>
            <label htmlFor={'bankAccountType'}>Bank account type</label>
            <div className='checkboxes'>
              {accountTypesOptions}
            </div>
          </div>
          <div className='col-md-6'>
            <Input onChange={(e) => setAttribute(id, 'bankAccountNumber', e.target.value)} name={'bankAccountNumber'} type={'text'} label={'Bank account number'} value={banking.bankAccountNumber}/>
          </div>
          <div className='col-md-6'>
            <Input onChange={(e) => setAttribute(id, 'debitOrderDay', e.target.value)} name={'debitOrderDay'} type={'number'} label={'Debit order day (1 - 31'} value={banking.debitOrderDay}/>
          </div>
          <div className='col-md-6'>
            <Input onChange={(e) => setAttribute(id, 'signatureValue', e.target.value)} name={'signature'} type={'text'} label={'Enter full name as signature on the contract'} value={banking.signatureValue}/>
          </div>
        </fieldset>
      </RegistrationStep>
      <div className="col-md-12 notification notice margin-top-30">
        Please note that this debit order is effective immediately of the Chosen date and that the activation fee shall
        be debited from your account with immediate effect and is non-refundable. The debit order may be cancelled with
        the implications contained in the terms and conditions.
      </div>
    </Fragment>
  )
});

export default Banking;
