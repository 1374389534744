import { toast } from 'react-toastify';

import client from '../utils/client';
import {isPresent} from '../utils/objects';
import {ACCOMMODATION_OPTION_QUERY, ACCOMMODATION_WITH_OPTIONS_QUERY} from '../queries/accommodations';
import {SAVE_ACCOMMODATION_OPTION_MUTATION} from '../mutations/accommodations'

const fetchAccommodationWithOptions = (accommodationId, callback) => {
  client.query({ query: ACCOMMODATION_WITH_OPTIONS_QUERY, variables: { accommodationId, currentOnly: true }})
    .then(({ data }) => {
      if(data && data.accommodation){
        callback(data.accommodation)
      } else {
        console.error(data)
      }
    })
    .catch(error => console.error(error))
}

const fetchAccommodationOption = (id, callback) => {
  client.query({ query: ACCOMMODATION_OPTION_QUERY, variables: { id }})
  .then(({ data }) => {
    if(data && data.accommodationOption){
      callback(data.accommodationOption)
    } else {
      console.error(data)
    }
  })
  .catch(error => console.error(error))
}

const saveAccommodationOption = (accommodationOption, callback) => {
  const args = accommodationOption.id ? prepareAccommodationOptionForSave(accommodationOption) : prepareAccommodationOptionForCreate(accommodationOption)
  toast.info('Saving...');

  client.mutate({ mutation: SAVE_ACCOMMODATION_OPTION_MUTATION, variables: args })
    .then(({ data: { saveOption: { accommodationOption, errors } } }) => {
      if(accommodationOption){
        console.log(accommodationOption);
        toast.success('Saved successfully...');
        callback(accommodationOption)
      }

      if(errors) {
        errors.forEach((e) => toast.error(`Failed to save: ${e.fullMessage} `))
        console.error(errors)
      }
    })
    .catch(error => {
      toast.error(`A server error occurred: ${error}`);
      console.error(error)
    })
}

const prepareAccommodationOptionForCreate = ({ accommodationId, roomName, roomType, maxOccupancy }) => {
  return {
    accommodationId,
    roomName,
    roomType,
    maxOccupancy
  }
}

const prepareAccommodationOptionForSave = (option) => {
  return {
    id: option.id,
    accommodationId: option.accommodationId,
    maxOccupancy: isPresent(option.maxOccupancy) ? parseInt(option.maxOccupancy): option.maxOccupancy,
    roomName: option.roomName,
    roomType: option.roomType,
    rates: prepareRates(option.rates)
  }
}

const prepareRates = (rates) => {
  return rates.map((rate) => {
    return {
      baseRate: rate.baseRate,
      startDate: rate.startDate,
      endDate: rate.endDate,
      rateType: rate.rateType,
      rackRate: rate.rackRate
    }
  })
}

export default {
  fetchAccommodationWithOptions,
  fetchAccommodationOption,
  saveAccommodationOption
}
