import React, {useState} from 'react';
import {observer} from 'mobx-react';

import Input from '../Input';

const CreateAccommodationOptionForm = observer(({store, setFormVisible}) => {
  const [roomName, setRoomName] = useState('');
  const [roomType, setRoomType] = useState('');
  const [maxOccupancy, setMaxOccupancy] = useState(2);

  const updateAttribute = (attribute, value, callback) => {
    callback(value);
  }

  const saveAndClose = () => {
    store.createAccommodationOption({roomName, roomType, maxOccupancy});
    setFormVisible(false);
  }

  return(
    <div className='row'>
      <h3>Add Room</h3>
      <div className='col-md-4'>
        <Input
          onChange={(e) => { updateAttribute('roomName', e.target.value, setRoomName)}}
          name={'roomName'}
          type={'text'}
          label='Room Name'
          value={roomName}
        />
      </div>
      <div className='col-md-4'>
        <Input
          onChange={(e) => { updateAttribute('roomType', e.target.value, setRoomType)}}
          name={'roomType'}
          type={'text'}
          label='Room Type'
          value={roomType}
        />
      </div>
      <div className='col-md-4'>
        <Input
          onChange={(e) => { updateAttribute('maxOccupancy', parseInt(e.target.value), setMaxOccupancy)}}
          name={'maxOccupancy'}
          type={'number'}
          label='Max Occupancy'
          value={maxOccupancy}
        />
      </div>
      <button onClick={saveAndClose} className='button'>Save</button>
      <button onClick={ () => setFormVisible(false) } className='border button'>Close</button>
    </div>
  )
});

export default CreateAccommodationOptionForm;
