import React from 'react';
import {observer} from 'mobx-react';

const DeductionsListing = observer(({getawayPointsDeductions, deleteDeduction}) => {
  const rows = getawayPointsDeductions.map(de => {
    return(
      <tr key={`deductions-${de.id}`}>
        <td>{de.deductionDate}</td>
        <td>{de.amount}</td>
        <td>{de.description}</td>
        <td>
          <button className='link-button' onClick={() => {deleteDeduction(de.id)}}>Delete</button>{' '}
        </td>
      </tr>
    )
  })

  return(
    <table className='basic-table'>
      <thead>
        <tr>
          <th>Deduction date</th>
          <th>Amount</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  )
})

export default DeductionsListing;
