import React from 'react';

const Errors = ({errors})  => {
  const errorList = errors.map((e, i) => {
    return(
      <li key={`error-${i}`}>{e.fullMessage}</li>
    )
  })

  if(errors.length > 0) {
    return (
      <div className="margin-bottom-20 margin-top-20 notification error">
        <ul>{errorList}</ul>
      </div>
    )
  } else {
    return null
  }
}

export default Errors;
