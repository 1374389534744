import {gql} from '@apollo/client'

export const CUSTOMER_BALANCE_AUDIT_PAGE_QUERY = gql`
  query customer($id: ID!) {
    customer(id: $id){
      name
      debitOrderCount
      unpaidCount
      paidCount
      currentWalletBalance
      scoreClient {
        extScoreClientId
      }
      getawayCreditsWallet {
        balance
      }
      getawayPointsWallet {
        id
        balance
      }
      billingAccounts {
        id
        startDate
        endDate
        endDateReason
      }
      businessMemberships {
        id
        startDate
        endDate
        level
        status
      }
      scoreCollectionTransactions(payableOnly: true) {
        id
        transactionDate
        amount
        generationType
      }
      scoreUnpaidCollectionTransactions {
        id
        transactionDate
        amount
        unpaidReason
      }
      scoreClientCollections {
        id
        status
        amount
        commencementDate
      }
      getawayPointsMovements {
        id
        amount
        description
        transactionDate
      }
      getawayPointsDeductions {
        id
        deductionDate
        amount
        description
      }
      pointsCalculationSummary {
        collectionId
        collectionAmount
        debitOrderCount
        paidCount
        unpaidCount
        membershipFee
        pointsTotal
      }
    }
  }
`;
