import React from 'react'
import { observer } from 'mobx-react';

import RegistrationStep from './RegistrationStep';
import Errors from '../Errors';
import Spinner from '../Spinner';

const ReviewSection = ({section}) => {
  return Object.entries(section).map((k) => {
    if(k[0] !== 'errors') return(<tr key={`review-row-${k[0]}`}><td>{k[0]}</td><td>{k[1]}</td></tr>)
  })
}

const Review = observer(({id, store}) => {
  if(store.registrationSuccess && store.customerId){
    window.location.assign(`/registrations/${store.customerId}`)
  }

  return(
    <RegistrationStep id={id}>
      <div className='col-md-12'>
        <p className="margin-bottom-20 margin-top-20 notification notice">
          Please ensure that the provided information is accurate and factual, as incorrect information may lead to
          the immediate termination of your membership.
        </p>
        <Errors errors={store.errors} />
        <table className={'review-table'}>
          <tbody>
            <tr>
              <td colSpan={2} className={'heading'}><h1>Membership</h1></td>
            </tr>
            <ReviewSection section={store.prettifyMembership()} />
            <tr>
              <td colSpan={2} className={'heading'}><h1>Biographical</h1></td>
            </tr>
            <ReviewSection section={store.prettifyBiographical()} />
            <tr>
              <td colSpan={2} className={'heading'}><h1>Address</h1></td>
            </tr>
            <ReviewSection section={store.prettifyAddress()} />
            <tr>
              <td colSpan={2} className={'heading'}><h1>Banking</h1></td>
            </tr>
            <ReviewSection section={store.prettifyBanking()} />
          </tbody>
        </table>
        <hr />
      </div>
      <div className={'bottom-fixed'}>
        { store.submitting ? <Spinner size={'small'}/> : <button className='button preview vivid' onClick={store.save}>Confirm and save</button> }
      </div>
    </RegistrationStep>
  )
});

export default Review;
