import React from 'react';
import {observer} from 'mobx-react';

import Spinner from '../Spinner';
import CreateAccommodationOption from './CreateAccommodationOption';

const AccommodationOptionsListing = ({ store }) => {
  const accommodation = store.accommodationWithOptions
  const lowestRate = (rates) => {
    if(rates === null || rates === undefined || rates.length < 1) return `No rates to display`;
    if(rates.length === 1) return `${rates[0].baseRate} ZAR`

    // Calling .slice() since the array at this point is an observable
    const lowest = rates.slice().sort((a, b) => { return a.baseRate > b.baseRate })[0]
    return `${lowest.baseRate} ZAR`
  }

  if(accommodation && accommodation.accommodationOptions){
    const options = accommodation.accommodationOptions.map((option) => {
      return(
        <tr key={`option-${option.id}`}>
          <td>{option.id}</td>
          <td>
            <a href={`/admin/accommodations/${accommodation.slug}/accommodation_options/${option.id}/edit`}
               className='underlined blue-link'> {option.roomName}
            </a>
          </td>
          <td>{option.roomType}</td>
          <td>{option.maxOccupancy}</td>
          <td>{lowestRate(option.rates)}</td>
        </tr>
      )
    })

    return(
      <div>
        <table className='basic-table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Room Name</th>
              <th>Room Type</th>
              <th>Max Occupancy</th>
              <th>Lowest Rate</th>
            </tr>
          </thead>
          <tbody>
            { options }
          </tbody>
        </table>
        <CreateAccommodationOption store={store} />
      </div>
    )
  }

  return <Spinner size={'small'} />
}

export default observer(AccommodationOptionsListing)
