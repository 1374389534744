// Entry point for the build script in your package.json

import 'trix';
import '@rails/actiontext'
import Rails from '@rails/ujs';

import AccommodationOptionsWrapper from './components/accommodations/AccommodationOptionsWrapper';
import AccommodationsWrapper from './components/accommodations/AccommodationsManagement';
import CustomerDetailsWrapper from './components/customers/CustomerDetailsWrapper';
// Not really using booking management, so commenting out for now
// import BookingsManagementWrapper from './components/bookings/BookingsManagementWrapper';
import EditAccommodationOptionWrapper from './components/accommodations/EditAccommodationOption';
import PackageSummary from './components/PackageSummary';
import PricingCalculator from './components/PricingCalculator';
import QuickBookingRequestForm from './components/booking_requests/QuickBookingRequestForm';
import RegistrationWizard from './components/registrations/RegistrationWizard';
import TeamBrowser from './components/TeamBrowser';

Rails.start();

let ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext({
  AccommodationOptionsWrapper,
  AccommodationsWrapper,
  CustomerDetailsWrapper,
  EditAccommodationOptionWrapper,
  PackageSummary,
  PricingCalculator,
  QuickBookingRequestForm,
  RegistrationWizard,
  TeamBrowser
});

import "@hotwired/turbo-rails"
import "./controllers"

import "flowbite";
