const BASE_PATH = '/admin/accommodations'

export const url = (current) => {
  return `${BASE_PATH}/${current}`
}

export const NEW_ACCOMMODATION_PATH = url('new');
export const newAccommodationUrl = () => { return NEW_ACCOMMODATION_PATH };
export const accommodationOptionsUrl = (slug) => { return url(`${slug}/accommodation_options`)}

export const accommodationUrl = (slug) => url(slug)
