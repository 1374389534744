import {gql} from '@apollo/client'

export const BOOKING_ITEM_QUERY = gql`
    query bookingItem($bookingItemId: ID!) {
        bookingItem(bookingItemId: $bookingItemId){
            description
            price {
                id
                rateOverride
            }
        }
    }
`;

export const BOOKING_QUERY = gql`
    query bookings($bookingId: ID) {
        booking(bookingId: $bookingId) {
            id
            customerId
            summary {
                itemType
                amountAdded
                amountBooked
                amountConfirmed
            }
            stays {
                id
                bookingItemId
                description
                nights
                price {
                    rateId
                    rateOverride
                }
            }
        }
    }
`;

export const CUSTOMER_BOOKINGS_QUERY = gql`
    query customerBookings {
        customerBookings {
            id
            startDate
            description
        }
    }
`;

export const SEARCH_ROOM_PRICES_QUERY = gql`
    query priceSearch($input: priceSearchInput!) {
        options: pricedAccommodationOptions(searchInput: $input){
            accommodation {
                name
            }
            accommodationOption {
                id
                roomName
                roomType
                maxOccupancy
            }
            rate {
                total
                totalRateDetails
                singlePerNight
                doublePerNight
                perNightRate
                isValidRate
                nights
                roomCorrection
            }
        }
    }
`;

